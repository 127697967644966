'use client';

import { atom } from 'jotai';

import { debounce } from '@core/Utils/Debounce';

import { ADS_TABLET_BP } from '../Constants/Constants';
import { AdUnitId, adUnits } from '../Constants/Units';
import { AdUnit } from '../Entities/AdUnit.entity';

import { adSlotAtom } from './AdSlot.atom';
import { adWidthAtom } from './AdWidth.atom';

type Props = [id: AdUnitId, targeting: Record<string, string>];

export const showAdAtom = atom(null, (get, set, ...props: Props) => {
  let slot: googletag.Slot | undefined | null;
  const [unitId, targeting] = props;

  const adUnit: AdUnit = adUnits[unitId];

  const initWidth = window.innerWidth >= ADS_TABLET_BP ? 'tabletAndDesktop' : 'mobile';
  set(adWidthAtom, initWidth);
  set(adSlotAtom(unitId), { isInitialised: false, isViewable: false });

  const shouldRequestNewCreative = () => {
    const savedWidth = get(adWidthAtom);
    const newWidth = window.innerWidth >= ADS_TABLET_BP ? 'tabletAndDesktop' : 'mobile';
    if (adUnit.useSizeMap && savedWidth !== newWidth) {
      set(adWidthAtom, newWidth);
      refreshSlot();
    }
  };

  const refreshSlot = () => {
    const slotAtom = get(adSlotAtom(unitId));
    if (slot && slotAtom.isViewable && window?.googletag && window.googletag?.pubadsReady) {
      window?.googletag.pubads().refresh([slot], { changeCorrelator: false });
    }
  };

  window.googletag = window.googletag || { cmd: [] };

  window.googletag.cmd.push(function () {
    window.googletag
      ?.pubads()
      ?.getSlots()
      ?.forEach(function (slot) {
        if (slot.getSlotElementId() === unitId) {
          window.googletag.destroySlots([slot]);
        }
      });

    slot = window.googletag
      .defineSlot(adUnit.path, adUnit.sizes as googletag.GeneralSize, unitId)
      .addService(window.googletag.pubads());

    //assign add sizes to breakpoints
    if (adUnit.responsiveSizeMap && adUnit.useSizeMap) {
      const mapping = window?.googletag.sizeMapping();

      for (const [key, value] of Object.entries(adUnit.responsiveSizeMap)) {
        mapping.addSize([Number(key), 0], value as googletag.GeneralSize);
      }

      const sizes = mapping.build();
      slot.defineSizeMapping(sizes!);
    }

    //custom sizes for LeaderBoard and mobile banners
    if (adUnit.path.includes('Leaderboard') && adUnit.useSizeMap) {
      const mapping = window?.googletag
        .sizeMapping()
        .addSize([1024, 0], [])
        .addSize([776, 0], adUnit.sizes as googletag.GeneralSize)
        .addSize([0, 0], [])
        .build();
      slot.defineSizeMapping(mapping!);
    }

    if (adUnit.path.includes('Banner_Mobile_Floating') && adUnit.useSizeMap) {
      const mapping = window?.googletag
        .sizeMapping()
        .addSize([776, 0], [])
        .addSize([0, 0], adUnit.sizes as googletag.GeneralSize)
        .build();
      slot.defineSizeMapping(mapping!);
    }

    slot.clearTargeting();
    if (Object.keys(targeting).length) slot.updateTargetingFromMap(targeting);

    googletag.pubads().enableLazyLoad({
      // Fetch slots within 5 viewports.
      fetchMarginPercent: 0,
      // Render slots within 2 viewports.
      renderMarginPercent: 0,
      // Double the above values on mobile, where viewports are smaller
      // and users tend to scroll faster.
      mobileScaling: 2.0,
    });

    window.googletag.enableServices();
    set(adSlotAtom(unitId), { slot });

    // Register event handlers to observe lazy loading behavior.
    googletag.pubads().addEventListener('slotRequested', function (event) {
      if (event.slot.getSlotElementId() !== unitId) return;
    });

    googletag.pubads().addEventListener('slotOnload', function (event) {
      if (event.slot.getSlotElementId() !== unitId) return;
    });

    window.googletag.pubads().addEventListener('slotRenderEnded', function (event) {
      if (event.slot.getSlotElementId() !== unitId) return;

      set(adSlotAtom(unitId), {
        isInitialised: true,
        isViewable: !event.isEmpty,
        renderedHeight: event?.size?.[1],
      });
    });
  });

  window.addEventListener(
    'resize',
    debounce(() => shouldRequestNewCreative(), 500),
  );

  return slot;
});
