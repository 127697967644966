import { SortTypes } from '@core/Entities/Search/Sort.entity';
import { Tenant } from '@whitelabel/Entities/Tenants.entity';

type SpecialParamsType = {
  [key in Tenant]: {
    [key in SortTypes]?: string;
  };
};

export const tenantSpecialParams: SpecialParamsType = {
  rac: {
    'relevance-desc': 'rac-relevance-desc',
  },
};
