import { MPU_SIZE } from '../UnitSizes';
import { AdUnit } from '../../Entities/AdUnit.entity';

import { PATH_BASE } from '../Constants';

export const PdpMainMPU = {
  path: `${PATH_BASE}/MPU1_UK`,
  sizes: [MPU_SIZE],
  defaultHeight: MPU_SIZE[1],
  maxWidth: 960,
  minHeight: 250,
  responsiveSizeMap: {
    1386: [],
    1024: [MPU_SIZE],
    768: [],
    0: [],
  },
  useSizeMap: true,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'MPU 1 UK banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'MPU 1 UK banner is loaded',
    },
  },
} as const satisfies AdUnit;
