import { DialogPos, PositionDetails } from './Tooltip.entities';

const DIALOG_OFFSET = 8;
const DIALOG_MAX_WIDTH = 288;
const DEFAULT_POS = 'bottom';

const getPosParts = (position: DialogPos) => {
  const parts = position.split('-');
  return { targetSide: parts[0], contentFlow: parts[1] };
};

const calcCoordsforPos = (element: HTMLButtonElement, position: DialogPos): PositionDetails => {
  const targetBounds = element.getBoundingClientRect();
  const { x, y, width: w, height: h } = targetBounds;
  const { targetSide, contentFlow } = getPosParts(position);
  const pageX = x;
  const pageY = y;
  let dialogX = pageX;
  let dialogY = pageY;

  if (targetSide === 'top' || targetSide === 'bottom') {
    dialogY = targetSide === 'top' ? pageY - DIALOG_OFFSET : pageY + h + DIALOG_OFFSET;

    if (!contentFlow) {
      dialogX = pageX + w / 2;
    } else if (contentFlow === 'right') {
      dialogX = pageX;
    } else if (contentFlow === 'left') {
      dialogX = pageX + w;
    }
  }

  if (targetSide === 'left' || targetSide === 'right') {
    dialogX = targetSide === 'left' ? pageX - DIALOG_OFFSET : pageX + w + DIALOG_OFFSET;

    if (!contentFlow) {
      dialogY = pageY + h / 2;
    } else if (contentFlow === 'top') {
      dialogY = pageY + h;
    } else if (contentFlow === 'bottom') {
      dialogY = pageY;
    }
  }

  return { coords: [dialogX, dialogY], name: position };
};

//NOTE: at the moment we can only check left and right bounds
//Because we know that max width of the dialog, but as there is no max height
//and is doesn't exist in the dom at this point, we cant know the height
const willDialogFitInVP = (posDetails: PositionDetails) => {
  const { targetSide, contentFlow } = getPosParts(posDetails.name);
  const [x] = posDetails.coords;
  const DIALOG_HALF_WIDTH = DIALOG_MAX_WIDTH / 2;
  const isLeftFacing = targetSide === 'left' || contentFlow === 'left';
  const isRightFacing = targetSide === 'right' || contentFlow === 'right';

  const leftDiff = (x - DIALOG_MAX_WIDTH) * -1;
  const rightDiff = x + DIALOG_MAX_WIDTH - window.innerWidth;
  const centreLeftDiff = (x - DIALOG_HALF_WIDTH) * -1;
  const centreRightDiff = x + DIALOG_HALF_WIDTH - window.innerWidth;

  if (isLeftFacing && leftDiff > 0) {
    return {
      willFit: false,
      diff: leftDiff,
    };
  }

  if (isRightFacing && rightDiff > 0) {
    return {
      willFit: false,
      diff: rightDiff,
    };
  }

  if (
    !contentFlow &&
    (targetSide === 'top' || targetSide === 'bottom') &&
    (centreLeftDiff > 0 || centreRightDiff > 0)
  ) {
    return {
      willFit: false,
      diff: Math.max(centreLeftDiff, centreRightDiff),
    };
  }

  return {
    willFit: true,
    diff: 0,
  };
};

//if the requested position wont fit in the viewport, try the next position along horizontally
const roundRobinPos = (position: DialogPos): DialogPos => {
  if (!position) return DEFAULT_POS;
  const { targetSide, contentFlow } = getPosParts(position);

  if (targetSide === 'top' || targetSide === 'bottom') {
    if (contentFlow === 'left') {
      return targetSide;
    }
    if (!contentFlow) {
      return `${targetSide}-right`;
    }
    if (contentFlow === 'right') {
      return `${targetSide}-left`;
    }
  }

  if (targetSide === 'right') {
    if (!contentFlow) return 'left';
    return `left-${contentFlow}` as DialogPos;
  }

  if (targetSide === 'left') {
    if (contentFlow === 'top') return 'top';
    return 'bottom';
  }

  return DEFAULT_POS;
};

const getDialogCoords = (element: HTMLButtonElement, requestedPosition: DialogPos) => {
  let posDetails = calcCoordsforPos(element, requestedPosition);
  let attmpt = willDialogFitInVP(posDetails);
  let testPosition = roundRobinPos(requestedPosition);
  const outsideBoundsScores = [{ ...attmpt, posDetails: { ...posDetails } }];

  //we test it 2 times, so that all the possible round robin positions of a given position are tested
  while (!attmpt.willFit && outsideBoundsScores.length < 3) {
    const testPosDetails = calcCoordsforPos(element, testPosition);
    attmpt = willDialogFitInVP(testPosDetails);

    if (attmpt.willFit) {
      posDetails = testPosDetails;
    } else {
      testPosition = roundRobinPos(testPosition);
      outsideBoundsScores.push({ ...attmpt, posDetails: { ...testPosDetails } });
    }
  }

  if (attmpt.willFit) {
    return posDetails;
  } else {
    //if all positions fall outside VP bounds find the object with the smallest diff
    const closest = outsideBoundsScores.reduce((prev, current) =>
      prev.diff < current.diff ? prev : current,
    );
    return closest.posDetails;
  }
};

export { calcCoordsforPos, getDialogCoords, getPosParts, roundRobinPos, willDialogFitInVP };
