import { usePathname } from 'next/navigation';

//NOTE: this should only be used on a listing page (autos, locations, dealerships, listing_campaign)
// otherwise it will return false info
const useGetRoot = () => {
  const pathname = usePathname();

  if (pathname.includes('autos')) return 'autos';
  if (pathname.includes('dealerships')) return 'dealerships';
  if (pathname.includes('locations')) return 'locations';

  return pathname.split('/')[2];
};

export { useGetRoot };
