/**
 * Capitalizes the first letter of each word in a string.
 * Leaves the original capitalization for already capitalized words.
 */
export const capitalCase = (input: string) => {
  // Capitalize the first letter of each word
  const words = input.split(' ');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together to form the capitalized string
  const capitalizedString = capitalizedWords.join(' ');

  return capitalizedString;
};

/**
 * Capitalizes the first letter of each word in a string.
 * Ignores / overwrites existing capitalization.
 */
export const capitalCaseOnly = (input: string) => {
  return input
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
