// Utility type to convert snake_case string to camelCase
type SnakeToCamelCase<S extends string> = S extends `${infer Head}_${infer Tail}`
  ? `${Head}${Capitalize<SnakeToCamelCase<Tail>>}`
  : S;

// Recursive type to deeply convert all keys in an object to camelCase
export type DeepSnakeToCamelCase<T> = T extends (infer U)[]
  ? DeepSnakeToCamelCase<U>[]
  : T extends object
  ? {
      [K in keyof T as SnakeToCamelCase<string & K>]: DeepSnakeToCamelCase<T[K]>;
    }
  : T;

// Function to convert a snake_case string to camelCase
function snakeToCamel(s: string): string {
  return s.replace(/_([a-z])/g, (_, group) => group.toUpperCase());
}

// Recursive function to deeply convert an object with snake_case keys to camelCase keys
export function camelizeObject<T>(obj: T): DeepSnakeToCamelCase<T> {
  if (Array.isArray(obj)) {
    return obj.map(item => camelizeObject(item)) as any;
  } else if (obj !== null && typeof obj === 'object') {
    const newObj: any = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = snakeToCamel(key);
        newObj[newKey] = camelizeObject((obj as any)[key]);
      }
    }
    return newObj;
  }
  return obj as any;
}
