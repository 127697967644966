import { LEADERBOARD_SIZE } from '../UnitSizes';
import { AdUnit } from '../../Entities/AdUnit.entity';

import { PATH_BASE } from '../Constants';

export const LeaderboardUnit = {
  path: `${PATH_BASE}/Leaderboard`,
  sizes: [LEADERBOARD_SIZE],
  defaultHeight: LEADERBOARD_SIZE[1],
  minWidth: 728,
  minHeight: 90,
  useSizeMap: true,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'Leaderboard banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'Leaderboard banner is loaded',
    },
  },
} as const satisfies AdUnit;
