'use client';

import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { AdUnitId } from '../Constants/Units';

interface AdSlotObj {
  isViewable: boolean;
  slot: googletag.Slot | null;
  isInitialised: boolean;
  renderedHeight?: number;
}

export const adSlotAtom = atomFamily((adSlot: AdUnitId) =>
  atom(
    {
      isViewable: false,
      slot: null,
      isInitialised: false,
    } as AdSlotObj,
    (get, set, object) => {
      set(adSlotAtom(adSlot), { ...get(adSlotAtom(adSlot)), ...object });
    },
  ),
);
