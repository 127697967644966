export const PATH_BASE = '/9259737';
export const ADS_TABLET_BP = 768;
export const FALLBACK_TIMEOUT = 250;
export const BLOCKER_FALLBACK_TIMEOUT = 750;

export const PDP_TARGETING = {
  leaderboard: 'PDP_Leaderboard',
  mainListing: 'PDP_MainListing',
  secondaryListing: 'PDP_SecondaryListing',
  sidebar: 'PDP_Sidebar',
};
