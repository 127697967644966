import React, { FC } from 'react';

import { RadioButtonProps } from './RadioButton.types';

import styles from './Styles/Default.module.css';

export const RadioButton: FC<RadioButtonProps> = React.forwardRef<HTMLDivElement, RadioButtonProps>(
  ({ checked, onChange, disabled, error, dataTestId, className, ...otherProps }, ref) => {
    return (
      <div
        className={`${styles.wrapper} ${error ? styles.error : ''} ${className ? className : ''}`}
        data-test-id={dataTestId}
        ref={ref}
      >
        <input
          checked={checked}
          className={styles.radio}
          disabled={disabled}
          onChange={onChange}
          type="radio"
          {...otherProps}
        />
        <span className={styles.fakeRadio} />
      </div>
    );
  },
);

RadioButton.displayName = 'RadioButton';

export default RadioButton;
