'use client';

import { useEffect } from 'react';

export const useIntersectionObserver = (
  $element: React.RefObject<HTMLElement>,
  callback: (entry: IntersectionObserverEntry) => void,
) => {
  useEffect(() => {
    if (!$element.current) return;

    const intersectionObserver = new IntersectionObserver(([entry]) => callback(entry));

    intersectionObserver.observe($element.current);
    return () => intersectionObserver.disconnect();
  }, [$element.current]);

  return $element;
};
