'use client';

import { atomWithStorage } from 'jotai/utils';

import Cookies from 'js-cookie';

const cookieStorage = {
  getItem: (key: string) => {
    const cookieValue = Cookies.get(key);
    try {
      return cookieValue ? JSON.parse(cookieValue) : null;
    } catch (error) {
      return null;
    }
  },
  setItem: (key: string, value: string | null) => Cookies.set(key, JSON.stringify(value)),
  removeItem: (key: string) => Cookies.remove(key),
};

export const authTokenAtom = atomWithStorage<string | null>('authToken', null, cookieStorage);
