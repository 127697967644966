/* eslint-disable @typescript-eslint/naming-convention */

'use client';
import { borderControlApiUrls } from '../Constants/BorderControlApiUrls';

import { User } from '../Entities/Account.entity';
import { createApiDataAtom } from '../Utils/CreateApiDataAtom';

const { asyncAtom: asyncUserProfileAtom, unwrappedAtom: userProfileAtom } = createApiDataAtom<User>(
  {
    url: borderControlApiUrls.users.profile,
  },
);

export { asyncUserProfileAtom, userProfileAtom };
