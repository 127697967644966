import { AdUnit } from '../../Entities/AdUnit.entity';
import { BILLBOARD_SIZE, MPU_SIZE } from '../UnitSizes';

import { PATH_BASE } from '../Constants';

const commonProps = {
  path: `${PATH_BASE}/Billboard1_UK`,
  sizes: [BILLBOARD_SIZE, MPU_SIZE],
  minWidth: 960,
  minHeight: 250,
  defaultWidth: BILLBOARD_SIZE[0],
  defaultHeight: BILLBOARD_SIZE[1],
  useSizeMap: true,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'UK webapp Billboard 1 banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'Billboard 1 UK banner is viewable',
    },
  },
};

const commonProps2 = {
  ...commonProps,
  path: `${PATH_BASE}/Billboard2_UK`,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'UK webapp Billboard 2 banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'Billboard 2 UK banner is viewable',
    },
  },
};

const commonProps3 = {
  ...commonProps,
  path: `${PATH_BASE}/Billboard3_UK`,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'UK webapp Billboard 3 banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'Billboard 3 UK banner is viewable',
    },
  },
};

export const BillboardUnit1 = {
  ...commonProps,
  responsiveSizeMap: {
    1440: [BILLBOARD_SIZE],
    1280: [BILLBOARD_SIZE],
    1024: [BILLBOARD_SIZE],
    768: [],
    0: [],
  },
} as const satisfies AdUnit;

export const BillboardUnit2 = {
  ...commonProps2,
  responsiveSizeMap: {
    1440: [BILLBOARD_SIZE],
    1280: [BILLBOARD_SIZE],
    1024: [BILLBOARD_SIZE],
    768: [],
    0: [],
  },
} as const satisfies AdUnit;

export const BillboardUnit3 = {
  ...commonProps3,
  responsiveSizeMap: {
    1440: [BILLBOARD_SIZE],
    1280: [BILLBOARD_SIZE],
    1024: [BILLBOARD_SIZE],
    768: [],
    0: [],
  },
} as const satisfies AdUnit;

export const No1280BillboardUnit1 = {
  ...commonProps,
  responsiveSizeMap: {
    1440: [BILLBOARD_SIZE],
    1280: [],
    1024: [BILLBOARD_SIZE],
    768: [],
    0: [],
  },
} as const satisfies AdUnit;

export const No1280BillboardUnit2 = {
  ...commonProps2,
  responsiveSizeMap: {
    1440: [BILLBOARD_SIZE],
    1280: [],
    1024: [BILLBOARD_SIZE],
    768: [],
    0: [],
  },
} as const satisfies AdUnit;
