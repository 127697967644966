import { AdUnit } from '../../Entities/AdUnit.entity';
import { BILLBOARD_SIZE, MPU_SIZE } from '../UnitSizes';

import { PATH_BASE } from '../Constants';

export const PdpMainBillboardUnit = {
  sizes: [BILLBOARD_SIZE, MPU_SIZE],
  minWidth: 960,
  minHeight: 250,
  defaultWidth: BILLBOARD_SIZE[0],
  defaultHeight: BILLBOARD_SIZE[1],
  responsiveSizeMap: {
    1386: [BILLBOARD_SIZE],
    1280: [],
    960: [],
    768: [],
    0: [],
  },
  useSizeMap: true,
  path: `${PATH_BASE}/Billboard1_UK`,
  trackingData: {
    renderEvent: {
      name: 'UK_webapp_slot_render_ended',
      label: 'UK webapp Billboard 1 banner is loaded',
    },
    impressionEvent: {
      name: 'UK_webapp_impression_viewable',
      label: 'Billboard 1 UK banner is viewable',
    },
  },
} as const satisfies AdUnit;
